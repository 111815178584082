@font-face {
  font-family: 'Lato Light';
  src:
    url('lato-light.woff2') format('woff2'),
    url('lato-light.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Lato Light Italic';
  src:
    url('lato-light-italic.woff2') format('woff2'),
    url('lato-light-italic.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Lato Regular';
  src:
    url('lato-regular.woff2') format('woff2'),
    url('lato-regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Lato Italic';
  src:
    url('lato-italic.woff2') format('woff2'),
    url('lato-italic.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Lato Medium';
  src:
    url('lato-medium.woff2') format('woff2'),
    url('lato-medium.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Lato Medium Italic';
  src:
    url('lato-medium-italic.woff2') format('woff2'),
    url('lato-medium-italic.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Lato Bold';
  src:
    url('lato-bold.woff2') format('woff2'),
    url('lato-bold.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Lato Bold Italic';
  src:
    url('lato-bold-italic.woff2') format('woff2'),
    url('lato-bold-italic.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Lato Black';
  src:
    url('lato-black.woff2') format('woff2'),
    url('lato-black.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Lato Semibold';
  src:
    url('lato-semibold.woff2') format('woff2'),
    url('lato-semibold.woff') format('woff');
  font-weight: 400;
}
